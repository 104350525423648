<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiTruckDelivery }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          color="accent"
          v-if="activeTabs == 'PurchaseOrder'"
          v-show="currentUser.role_id == 1"
          rounded
          @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
        >
          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
          <span>Add Purchase Order</span>
        </v-btn>

        <v-btn
          color="accent"
          v-if="activeTabs == 'Ordered'"
          v-show="currentUser.role_id == 1"
          rounded
          @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
        >
          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
          <span>Add Purchase Order</span>
        </v-btn>

        <v-btn
          color="accent"
          v-if="activeTabs == 'Received'"
          v-show="currentUser.role_id == 1"
          rounded
          @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
        >
          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
          <span>Add Purchase Order</span>
        </v-btn>

        <v-btn
          color="accent"
          v-if="activeTabs == 'ReturnOrder'"
          v-show="currentUser.role_id == 1"
          rounded
          @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
        >
          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
          <span>Add Purchase Order</span>
        </v-btn>

        <v-btn
          v-if="activeTabs == 'Vendor'"
          v-show="currentUser.role_id == 1"
          color="accent"
          rounded
          @click.prevent="$router.push({ name: 'addnewvendor' })"
        >
          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
          <span>Add Vendor</span>
        </v-btn>
      </div>
      <div v-for="(permission, index) in permission" :key="index">
        <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
          <v-btn
            color="accent"
            v-if="activeTabs == 'PurchaseOrder'"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Purchase Order</span>
          </v-btn>

          <v-btn
            color="accent"
            v-if="activeTabs == 'Ordered'"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Purchase Order</span>
          </v-btn>

          <v-btn
            color="accent"
            v-if="activeTabs == 'Received'"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Purchase Order</span>
          </v-btn>
          <v-btn
            color="accent"
            v-if="activeTabs == 'ReturnOrder'"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            rounded
            @click.prevent="$router.push({ name: 'addnew_purchaseorder' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Purchase Order</span>
          </v-btn>
          <v-btn
            v-if="activeTabs == 'Vendor'"
            v-show="usermenu.create == 1 && permission.role_id == 3"
            color="accent"
            rounded
            @click.prevent="$router.push({ name: 'addnewvendor' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Add Vendor</span>
          </v-btn>
        </div>
      </div>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs>
      <v-tab @click.prevent="activeTab('PurchaseOrder')">
        <v-icon left>{{ icons.mdiCart }}</v-icon>
        Purchase Orders
      </v-tab>
      <v-tab @click.prevent="activeTab('Ordered')">
        <v-icon left> {{ icons.mdiCartCheck }} </v-icon>
        Ordered
      </v-tab>
      <v-tab @click.prevent="activeTab('Received')">
        <v-icon left> {{ icons.mdiCartPlus }} </v-icon>
        Received
      </v-tab>
      <v-tab @click.prevent="activeTab('ReturnOrder')">
        <v-icon left> {{ icons.mdiCartRemove }} </v-icon>
        Return Orders
      </v-tab>
      <v-tab @click.prevent="activeTab('Vendor')">
        <v-icon left> {{ icons.mdiAccountMultiple }} </v-icon>
        Vendors
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <PurchaseOrder :is="activeTabs" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Ordered :is="activeTabs" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Received :is="activeTabs" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <ReturnOrder :is="activeTabs" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <Vendor :is="activeTabs" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import PurchaseOrder from '@/components/purchasing/PurchaseOrders.vue'
import Ordered from '@/views/purchasing/OrderedList.vue'
import Received from '@/views/purchasing/OrderedReceivedList.vue'
import ReturnOrder from '@/views/purchasing/ReturnOrderList.vue'
import Vendor from '@/components/inventory/Vendor/Vendor.vue'
import {
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiAccountSupervisor,
  mdiCar,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiBell,
  mdiCalendarAccount,
  mdiTruckDelivery,
  mdiClose,
  mdiArchiveArrowDown,
  mdiTools,
  mdiCarInfo,
  mdiAccountHardHat,
  mdiAccountMultiple,
  mdiCash,
  mdiCart,
  mdiCartCheck,
  mdiCartPlus,
  mdiCartRemove,
} from '@mdi/js'
export default {
  components: {
    PurchaseOrder,
    Ordered,
    Received,
    ReturnOrder,
    Vendor,
  },

  created: function() {
    this.getAuthorizations()
  },

  data() {
    return {
      activeTabs: 'PurchaseOrder',
      permission: {},
      icons: {
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiAccountSupervisor,
        mdiTruckDelivery,
        mdiCar,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiBell,
        mdiCalendarAccount,
        mdiClose,
        mdiArchiveArrowDown,
        mdiTools,
        mdiCarInfo,
        mdiAccountHardHat,
        mdiAccountMultiple,
        mdiCash,
        mdiCart,
        mdiCartCheck,
        mdiCartPlus,
        mdiCartRemove,
      },
    }
  },
  methods: {
    activeTab(selectedTab) {
      this.activeTabs = selectedTab
      this.getAuthorizations()
    },

    getAuthorizations: function() {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 5,
          submenu_id: '',
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  computed: {
    ...mapGetters({
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style></style>
