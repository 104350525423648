<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="purchaseOrders"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="vendor_name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(purchaseOrder, index1) in items" :key="index1">
            <td>{{ purchaseOrder.purchase_order_no }}</td>
            <td>{{ formatDate(purchaseOrder.created_at) }}</td>
            <td>
              {{ purchaseOrder.part_number }}
            </td>
            <td>
              <div v-for="vendor in purchaseOrder.vendors" :key="vendor.vendor_id">
                <span>{{ vendor.vendor_name }}</span>
              </div>
            </td>
            <td>
              <v-chip class="ma-2" color="success" label>
                {{ purchaseOrder.status }}
              </v-chip>
            </td>
            <td>{{ purchaseOrder.payment_method }}</td>
            <td>{{ purchaseOrder.tracking_number }}</td>

            <template v-for="(purchaseItem, index) in purchaseOrder.purchase_order_items">
              <td :key="`item1-${index}`">{{ purchaseItem.item }}</td>
              <td :key="`item2-${index}`">{{ purchaseItem.quanity }}</td>
              <td :key="`item3-${index}`">{{ purchaseItem.retail_price }}</td>
              <td :key="`item4-${index}`">{{ purchaseItem.total }}</td>
            </template>
            <td>
              <v-menu offset-y bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn light icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click.prevent="
                      $router.push({ name: 'show_ordered-received', params: { id: purchaseOrder.purchase_id } })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon size="20" color="primary" class="mx-1" v-text="icons.mdiKeyboardReturn"></v-icon>
                      <v-list-item-title>Return Orders</v-list-item-title>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getVendors"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
  
  <script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiPhone,
  mdiEmail,
  mdiPencil,
  mdiClose,
  mdiCheckCircleOutline,
  mdiKeyboardReturn,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  created: function () {
    this.getPurchaseOrders()
  },

  data() {
    return {
      headers: [
        {
          text: 'PO #',
          value: 'purchase_order_no',
        },
        { text: 'Date Created', value: 'created_at' },
        { text: 'Part Number', value: 'part_number' },
        { text: 'Vendor', value: 'vendor_id' },
        { text: 'Status', value: 'status' },
        { text: 'Payment Method', value: 'payment_method' },
        { text: 'Tracking', value: 'tracking_number' },
        { text: 'Item', value: 'item' },
        { text: 'Qty', value: 'quantity' },
        { text: 'Retail Price', value: 'retail_price' },
        { text: 'Total', value: 'total' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      vendors: [],
      search: '',

      purchaseOrders: [],
      purchaseOrder: {},

      icons: {
        mdiAccountMultiple,
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPhone,
        mdiEmail,
        mdiPencil,
        mdiClose,
        mdiCheckCircleOutline,
        mdiKeyboardReturn,
      },
    }
  },

  methods: {
    getPurchaseOrders: function () {
      this.$store
        .dispatch('purchaseOrder/fetchPurchaseOrders', {
          status: 'ReturnOrder',
        })
        .then(response => {
          this.purchaseOrders = this.fetchPurchaseOrders
        })

        .catch(err => {
          console.log(err)
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchVendors: 'vendor/fetchVendors',
      fetchInventoryItems: 'inventory/fetchInventoryItems',
      fetchPurchaseOrders: 'purchaseOrder/fetchPurchaseOrders',
    }),
  },
}
</script>

